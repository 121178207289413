<div class="row" *transloco="let t">
  <div class="col-12">
    <h4>{{ t('confirmationKey') }}</h4>
    <hr class="line" />

    <div class="row">
      <div class="col-12">
        <h6 class="mb-0">{{ t('equipmentKey') }}</h6>

        <div class="row mb-2">
          <div class="col-6">
            {{ createdRequest?.equipmentDescription }}
          </div>

          <div class="col-6 text-right">
            {{ createdRequest?.equipmentId }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <h6 class="mb-0">{{ t('enterpriseKey') }}</h6>
          </div>

          <div class="col-6 text-right">
            {{ createdRequest?.enterprise }}
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6">
            <h6 class="mb-0">{{ t('areaKey') }}</h6>
          </div>

          <div class="col-6 text-right">
            {{ createdRequest?.area }}
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <h6 class="mb-0">{{ t('requesterKey') }}</h6>
          </div>

          <div class="col-6 text-right">
            {{ createdRequest?.requester }}
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6">
            {{ createdRequest?.no }}
          </div>

          <div class="col-6 text-right">
            {{ createdRequest?.description }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-6 text-center">
        <button class="btn1" (click)="redirectConfirmRequest()">
          {{ t('okKey') }}
        </button>
      </div>
    </div>
  </div>
</div>
