export const NAVIGATION_ITEMS = {
  technician: [
    {
      svg: 'dashboard.svg',
      routerLink: ['/technician/dashboard'],
      transKey: 'dashboardKey',
      code: 'TP-Dashboard',
    },
    {
      svg: 'equipment.svg',
      routerLink: ['/technician/equipment'],
      transKey: 'equipmentKey',
      code: 'TP-Equipment',
    },
    {
      svg: 'wo.svg',
      routerLink: ['/technician/my-work-orders'],
      transKey: 'myWorkOrdersKey',
      code: 'TP-My Work Order',
    },
    {
      svg: 'schedule.svg',
      routerLink: ['/technician/schedule'],
      transKey: 'taskScheduleKey',
      code: 'TP-Schedule',
    },
    {
      svg: 'myrequest.svg',
      routerLink: ['/technician/requests'],
      transKey: 'requestsKey',
      code: 'TP-Assigned',
    },
    {
      svg: 'purchase.svg',
      routerLink: ['/technician/purchase-order'],
      transKey: 'purchaseOrderKey',
      code: 'TP-Purchase',
    },
    {
      svg: 'timesheet.svg',
      routerLink: ['/technician/timesheet'],
      transKey: 'timesheetKey',
      code: 'TP-Timesheet',
    },
    {
      svg: 'reporting.svg',
      routerLink: ['/technician/reporting'],
      transKey: 'reportingKey',
      code: 'TP-Reporting',
    },
    {
      svg: 'settings.svg',
      routerLink: ['/technician/settings'],
      transKey: 'settingsKey',
      code: 'TP-Settings',
    },
  ],
  manager: [
    {
      svg: 'dashboard.svg',
      routerLink: ['/manager/dashboard'],
      transKey: 'dashboardKey',
      code: 'TP-Dashboard',
    },
    {
      svg: 'equipment.svg',
      routerLink: ['/manager/equipment'],
      transKey: 'equipmentKey',
      code: 'TP-Equipment',
    },
    {
      svg: 'resources.svg',
      routerLink: ['/manager/resources'],
      transKey: 'resourcesKey',
      code: 'TP-Settings',
    },
    {
      svg: 'dispatch.svg',
      routerLink: ['/manager/dispatch'],
      transKey: 'dispatchKey',
      code: 'TP-Dispatch',
    },
    {
      svg: 'work-white-24dp.svg',
      routerLink: ['/manager/job'],
      transKey: 'jobKey',
      code: 'TP-Job',
    },
    {
      svg: 'wo.svg',
      routerLink: ['/manager/my-work-orders'],
      transKey: 'myWorkOrdersKey',
      code: 'TP-My Work Order',
    },
    {
      svg: 'review.svg',
      routerLink: ['/manager/review'],
      transKey: 'reviewKey',
      code: 'TP-Review',
    },
    {
      svg: 'timesheet.svg',
      routerLink: ['/manager/timesheet-review'],
      transKey: 'timesheetKey',
      code: 'TP-Timesheet',
    },
    // {
    //   svg: 'foreman.svg',
    //   routerLink: ['/manager/foreman'],
    //   transKey: 'foremanKey',
    //   code: 'TP-Timesheet',
    // },
    {
      svg: 'purchase.svg',
      routerLink: ['/manager/purchase-order'],
      transKey: 'purchaseOrderKey',
      code: 'TP-Purchase',
    },
    {
      svg: 'foreman.svg',
      routerLink: ['/manager/form-data'],
      transKey: 'formDataKey',
      code: 'TP-Form-Data',
    },
    {
      svg: 'dmp.svg',
      routerLink: ['/manager/dmp'],
      transKey: 'maintenancePlanKey',
      code: 'TP-Dmp',
    },
    {
      svg: 'reporting.svg',
      routerLink: ['/manager/reporting'],
      transKey: 'reportingKey',
    },
    {
      svg: 'settings.svg',
      routerLink: ['/manager/settings'],
      transKey: 'settingsKey',
      code: 'TP-Settings',
    },
  ],
  customer: [
    {
      svg: 'dashboard.svg',
      routerLink: ['/customer/dashboard'],
      transKey: 'dashboardKey',
      code: 'TP-Dashboard',
    },
    {
      svg: 'equipment.svg',
      routerLink: ['/customer/equipment'],
      transKey: 'equipmentKey',
      code: 'TP-Equipment',
    },
    {
      svg: 'wo.svg',
      routerLink: ['/customer/my-work-orders'],
      transKey: 'myWorkOrdersKey',
      code: 'TP-My Work Order',
    },
    {
      svg: 'myrequest.svg',
      routerLink: ['/customer/requests'],
      transKey: 'requestsKey',
      code: 'TP-Assigned',
    },
    {
      svg: 'reporting.svg',
      routerLink: ['/customer/reporting'],
      transKey: 'reportingKey',
      code: 'TP-Reporting',
    },
    {
      svg: 'settings.svg',
      routerLink: ['/customer/settings'],
      transKey: 'settingsKey',
      code: 'TP-Settings',
    },
  ],
  requester: [
    //Commented out because it is not used in the app. Haven't removed it because it might be used in the future when Requester Portal Dashboard is implemented.
    // {
    //   svg: 'dashboard.svg',
    //   routerLink: ['/requester/dashboard'],
    //   transKey: 'dashboardKey',
    //   code: 'TP-Dashboard',
    // },
    {
      svg: 'equipment.svg',
      routerLink: ['/requester/equipment'],
      transKey: 'equipmentKey',
      code: 'TP-Equipment',
    },
    {
      svg: 'myrequest.svg',
      routerLink: ['/requester/requests'],
      transKey: 'requestsKey',
      code: 'TP-Assigned',
    },
    {
      svg: 'reporting.svg',
      routerLink: ['/requester/reporting'],
      transKey: 'reportingKey',
      code: 'TP-Reporting',
    },
    {
      svg: 'settings.svg',
      routerLink: ['/requester/settings'],
      transKey: 'settingsKey',
      code: 'TP-Settings',
    },
  ],
  vendor: [
    {
      svg: 'invoice.svg',
      routerLink: ['/vendor/invoice'],
      transKey: 'invoiceKey',
    },
    {
      svg: 'resources.svg',
      routerLink: ['/vendor/personnel'],
      transKey: 'personnelKey',
    },
  ],
};
