import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { MatDesignModule } from '@app/mat-design.module';
import { SchedulerUtilitiesService } from '@dispatch-services/scheduler-utilities.service';
import { DeleteAllNotification, DeleteNotification } from '@stores-actions/notification.action';
import { NotificationState } from '@stores-states/notification.state';

@Component({
  standalone: true,
  imports: [CommonModule, TranslocoModule, MatDesignModule],
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent implements OnInit {
  @Select(NotificationState.getNotifications) readonly notifications$!: Observable<Notification[]>;

  notifications: Notification[] = [];
  constructor(
    private dialogRef: MatDialogRef<NotificationComponent>,
    private store: Store,
    private schedulerUtilitiesService: SchedulerUtilitiesService
  ) {}

  ngOnInit(): void {
    this.notifications$.subscribe((x) => {
      this.notifications = x.slice().reverse();
    });
  }

  getRelativeDate(timeStamp: number): string {
    const current = new Date();
    const previous = new Date(timeStamp);
    return this.schedulerUtilitiesService.timeDifference(current, previous);
  }

  deleteAll(): void {
    this.store.dispatch(new DeleteAllNotification());
  }

  delete(data: any): void {
    this.store.dispatch(new DeleteNotification(data.timestamp));
  }

  close(): void {
    this.dialogRef.close();
  }
}
