import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MainPipeModule } from '@helpers/pipes/main-pipe.module';
import { DialogData } from '@models/dialog-data';

@Component({
  standalone: true,
  imports: [MainPipeModule],
  selector: 'app-img-diapo',
  templateUrl: './img-diapo.component.html',
  styleUrls: ['./img-diapo.component.css'],
})
export class ImgDiapoComponent implements OnInit {
  imgUrl?: any;
  srcImg?: string;
  src: string | ArrayBuffer | null = 'assets/bitmap.jpg';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
    this.srcImg = this.data.message;
    if (this.srcImg) {
      this.src = this.srcImg;
    }

    this.imgUrl = this.data.data;
    if (this.imgUrl) {
      this.preview(this.imgUrl);
    }
  }

  preview(file: Blob): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      this.src = reader.result;
    };
  }
}
