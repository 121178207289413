import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import {
  EquipmentFailureCode,
  RelatedRequirement,
  WorkOrderLine,
  WorkProcedure,
} from '@tag/graphql';
import { merge } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import {
  filter,
  finalize,
  map,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import { UpdateCardObject } from '@cards-stores/card.actions';
import { FormReportMaintenanceSettingsShort } from '@forms-models/form-report-maintenance-settings-short';
import { SuggestedTask } from '@forms-models/suggested-task';
import { FormService } from '@forms-services/form.service';
import { MaintenanceService } from '@forms-services/report-maintenance.service';
import { FormOptions } from '@forms-utils/form-options.enum';
import { DateExtendedPipe } from '@helpers/pipes/date-extended.pipe';
import { SubscriptionsComponent } from '@helpers/subscriptions-component';
import { NAVIGATION_ITEMS } from '@menu/menu-items';
import { LoginOption } from '@models/login-option';
import { LookUpService } from '@request-services/look-up.service';
import { ModalService } from '@request-services/modal.service';
import { TAGLookUpEntity } from '@request-services/tag-lookup-entity';
import { Hotkeys } from '@services/hotkeys.service';
import { ImportAttachment } from '@shared-components/import-attachment/import-attachment';
import { AddNotification } from '@stores-actions/notification.action';
import { GetRequirementsBySource } from '@stores-actions/requirement.action';
import {
  GetWorkOrderLinesByWorkOrder,
  ReportWorkOrderLineMaintenance,
} from '@stores-actions/work-order-line.action';
import { AuthState } from '@stores-states/authentication.state';
import {
  RequirementState,
  RequirementStateObject,
} from '@stores-states/requirement.state';
import { WorkOrderLineState } from '@stores-states/work-order-line.state';
import {
  DocStorDocumentType,
  ReportMaintenance,
  UserConfig,
  WorkOrderDocumentType,
} from '@api/types';
import { FacilitiesGQL } from '@shared/apollo/queries/facility';
import { isBefore } from 'date-fns';

/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-form-report-maintenance',
  templateUrl: './form-report-maintenance.component.html',
  styleUrls: ['./form-report-maintenance.component.scss'],
})
export class FormReportMaintenanceComponent
  extends SubscriptionsComponent
  implements OnInit
{
  @Select(WorkOrderLineState.getSelectedWorkOrderLine)
  readonly selectedWorkOrderLine$!: Observable<WorkOrderLine>;
  @Select(AuthState.session) session$!: Observable<UserConfig>;

  @Input() dashboardUiChanges = false;
  @Input() set workOrderLine(inputWol: WorkOrderLine | null) {
    if (inputWol && Object.keys(inputWol).length > 0) {
      const wol = { ...inputWol };
      this.getAdditionalInfo(wol);
    }
  }
  @Output() formOptions = new EventEmitter<FormOptions>();

  // Forms
  ngForm = this.initializeForm();

  attachments: ImportAttachment[] = [];
  calculatedInformation = [
    {
      key: this.translocoService.translate('reportedWorkOrderLinesKey'),
      value: '',
    },
    {
      key: this.translocoService.translate('workOrderActualEstimatedTimeKey'),
      value: '',
    },
    {
      key: this.translocoService.translate('workOrderActualEstimatedUsageKey'),
      value: this.translocoService.translate('naKey'),
    },
  ];

  defaultFacility = '';
  expended = false;
  failureCodes$ = new Observable<EquipmentFailureCode[]>();
  feedbackType = 'General';
  formSetting = new FormReportMaintenanceSettingsShort();
  formValues: { key: string; value: any }[] = [];
  extendedFeedback = false;
  nonReportedLinesMessage = '';
  portalType: LoginOption | string;
  navigationItems = NAVIGATION_ITEMS;
  requirements: RequirementStateObject[] = [];
  selectedTask?: SuggestedTask;
  showOverlay = false;
  textTypeMaxLength = 50;
  wol?: WorkOrderLine;
  workOrderLines: WorkOrderLine[] = [];
  wp?: WorkProcedure;
  isTa: boolean;

  private isFinishedInDb = false;

  get isExtendedFeedback(): boolean {
    return this.extendedFeedback;
  }

  set isExtendedFeedback(value: boolean) {
    if (value) {
      this.ngForm.controls.feedbackDescription.clearValidators();
      this.ngForm.controls.extFeedbackDescription.setValidators([
        Validators.required,
      ]);
    } else {
      this.ngForm.controls.extFeedbackDescription.clearValidators();
      this.ngForm.controls.feedbackDescription.setValidators([
        Validators.required,
        Validators.maxLength(250),
      ]);
    }
    this.ngForm.controls.feedbackDescription.updateValueAndValidity();
    this.ngForm.controls.extFeedbackDescription.updateValueAndValidity();
    this.extendedFeedback = value;
  }

  get equipmentId() {
    return this.ngForm.controls.equipment_ID.value as string | null;
  }

  set equipmentId(eqId: string | null | undefined) {
    this.ngForm.controls.equipment_ID.setValue(eqId);
    this.ngForm.controls.equipment_ID.markAsDirty();
  }

  get actualTime() {
    return this.ngForm.controls.actual_Time.value;
  }

  get failureCode() {
    return this.ngForm.controls.failure_Code.value;
  }

  get isFinishedStatus(): boolean {
    return (
      (this.ngForm.controls.finishedWorkOrderLine?.value &&
        (this.wol?.finished ? this.wol.finished : false)) ??
      false
    );
  }

  get meterValue(): string {
    return this.formSetting.meter_Reading.enabled
      ? this.ngForm.controls.meterValue.value ?? ''
      : '';
  }

  get meterValueControl(): UntypedFormControl | null {
    return this.formSetting.meter_Reading.enabled
      ? (this.ngForm.controls.meterValue as UntypedFormControl)
      : null;
  }

  getMeterValueErrorMessage(): string {
    if (this.meterValueControl?.hasError('pattern')) {
      return 'Specific Value does not match the value in system. Test result will not be accepted.';
    }

    return '';
  }

  get resultDatetime() {
    return this.formSetting.meter_Reading.enabled
      ? this.ngForm.controls.result_Datetime.value
      : undefined;
  }

  get feedbackDescription() {
    return this.isExtendedFeedback
      ? this.ngForm.controls.extFeedbackDescription.value
      : this.ngForm.controls.feedbackDescription.value;
  }

  get isResultsInputRequired(): boolean {
    return !!this.wol?.resultsInputRequired;
  }

  get isValidationRequired(): boolean {
    return !!this.wol?.inputValidationRequired;
  }

  constructor(
    public formService: FormService,
    private formBuilder: FormBuilder,
    private readonly store: Store,
    private translocoService: TranslocoService,
    private facilitiesService: FacilitiesGQL,
    private maintenanceService: MaintenanceService,
    private lookupService: LookUpService,
    private hotkeys: Hotkeys,
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.session$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((session) => {
      this.formSetting = structuredClone(
        merge(this.formSetting, session?.reportMaintenance?.form?.short)
      );
      if (this.formSetting.additional_Information.enabled) {
        if (this.workOrderLines.length)
          this.processWorkOrderNumbers(this.workOrderLines);
        if (this.requirements.length)
          this.processWorkOrderUsageNumbers(this.requirements);
      }
    });

    this.portalType = this.store.selectSnapshot(AuthState.portalType);
    this.isTa = this.store.selectSnapshot(AuthState.isTa);
  }

  ngOnInit(): void {
    this.ngForm = this.initializeForm();
    this.ngForm.controls.finishedWorkOrderLine.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: any) => {
        if (value && (this.wol?.finished ? this.wol.finished : false))
          this.ngForm.controls.actual_Time.disable();
        else this.ngForm.controls.actual_Time.enable();
      });
    if (this.wol && !this.dashboardUiChanges) this.validateFormTechnician();
    if (this.wol?.workProcedureStep) this.getWorkProcedure();
    const company = this.store.selectSnapshot(AuthState.company);
    this.resetView();
    const facilityCode = this.wol?.facility || '';
    if (facilityCode)
      this.facilitiesService
        .fetch({ filter: `Code eq '${facilityCode}'` })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          map((x) => x.data.facilities.items[0])
        )
        .subscribe((x) => {
          this.defaultFacility = x.navisionLocation || '';
          this.changeDetectorRef.markForCheck();
        });

    this.hotkeys
      .addShortcut({ keys: 'control.enter', description: 'Submit Form' })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.confirmationResponse(true);
      });
  }

  handleBinaryResultValueChanged(e: any): void {
    this.ngForm.controls.meterValue.setValue(e.value);
    this.ngForm.controls.meterValue.markAsDirty();
  }

  resetView(): void {
    this.attachments = [];
    this.ngForm.controls.feedbackDescription.setValue('');
    this.ngForm.controls.extFeedbackDescription.setValue('');
    this.ngForm.controls.failure_Code.setValue('');

    this.ngForm.controls.feedback_Type.setValue('General');
    this.formService.selectedRequirements$.next([]);
    this.requirements = [];
    this.ngForm.controls.actual_Time.setValue(0);
    this.ngForm.controls.finishedWorkOrderLine.setValue(
      this.wol?.finished ? this.wol.finished : false
    );
  }

  getFailureCodes(): void {
    this.lookupService
      .lookUp(TAGLookUpEntity.failureCodes, this.wol?.equipmentId)
      .pipe(take(1))
      .subscribe((x) => {
        this.ngForm.controls.failure_Code.setValue(x?.failure_Code);
      });
  }

  confirmationResponse(confirmSubmit: boolean): void {
    if (this.wol && confirmSubmit) {
      const isEditFinishConfirmed = this.isFinishedInDb
        ? confirm(
            this.translocoService.translate(
              'ThisLineIsCurrentlyFinishedAreYouSureYouWantToEditIt'
            )
          )
        : true;
      if (isEditFinishConfirmed) {
        this.submitReport();
      }
    }
  }

  displayConfirmation(): void {
    this.showOverlay = true;
    this.formValues = [
      {
        key: this.translocoService.translate('durationKey'),
        value: this.ngForm.value.actual_Time + ' hrs',
      },
      {
        key: this.translocoService.translate('feedbackTypeKey'),
        value: this.feedbackType,
      },
      {
        key: this.translocoService.translate('failureCodeKey'),
        value: this.failureCode,
      },
      {
        key: this.translocoService.translate('maintenanceReportKey'),
        value: this.feedbackDescription,
      },
      {
        key: this.translocoService.translate('valueKey'),
        value: this.meterValue,
      },
      {
        key: this.translocoService.translate('resultDateKey'),
        value: this.resultDatetime,
      },
      {
        key: this.translocoService.translate('finishedKey'),
        value: this.ngForm.value.finishedWorkOrderLine,
      },
    ];
  }

  editActualTime(num: number): void {
    const control = this.ngForm.controls.actual_Time;
    const initValue = control.value;
    control.setValue((initValue || 0) + num);
    if (!this.ngForm.controls.actual_Time.valid) {
      const msg = this.translocoService.translate(
        'timeCannotBeLessThanhoursKeyParam',
        { hours: 0.01 }
      );
      this.store.dispatch(
        new AddNotification(msg, 'warning', 'Report Maintenance')
      );
    }
  }

  expend(): void {
    this.expended = !this.expended;
  }

  private getWorkProcedure(): void {
    if (this.wol?.workProcedureStep) {
      this.ngForm.disable({ emitEvent: false });
      this.formService
        .getWorkProcedure(this.wol?.workProcedureStep)
        .pipe(
          take(1),
          finalize(() => {
            this.ngForm.enable({ emitEvent: false });
            if (this.wol?.finished) this.ngForm.controls.actual_Time.disable();
          })
        )
        .subscribe((wp) => {
          this.wp = wp;

          const min = wp.numericAcceptableLow ?? 0;
          const max =
            min >= (wp.numericAcceptableHigh ?? 0)
              ? null
              : wp.numericAcceptableHigh ?? null;

          switch (wp.inputType) {
            case 'Meter Reading':
            case 'Numeric':
              this.ngForm.controls.meterValue.addValidators(
                Validators.min(min)
              );
              if (max) {
                this.ngForm.controls.meterValue.addValidators(
                  Validators.max(max)
                );
              }
              break;
            case 'Specific Value':
              if (wp.validationRequired && wp.specificValueRequired) {
                const specificValue = wp.specificValueRequired;
                const reg = new RegExp('^' + specificValue + '$');
                this.ngForm.controls.meterValue.addValidators(
                  Validators.pattern(reg)
                );
              }
              break;
            case 'Text':
              this.ngForm.controls.meterValue.addValidators(
                Validators.maxLength(this.textTypeMaxLength)
              );
              break;
            default:
              break;
          }
        });
    }
  }

  private getAdditionalInfo(wol: WorkOrderLine): void {
    this.isFinishedInDb = !!this.wol?.finished;
    this.wol = wol;
    this.selectedTask = {
      no: wol.workOrderNo,
      line: wol.lineNo,
      description: wol.description,
      duration: wol.actualTime ?? 0,
      type: '',
    } as SuggestedTask;
    this.store.dispatch(new GetWorkOrderLinesByWorkOrder(wol.workOrderNo));
    this.resetView();
    this.store.dispatch(
      new GetRequirementsBySource(wol.workOrderNo, wol.lineNo)
    );

    combineLatest([
      this.store.select(
        WorkOrderLineState.getWorkOrderLinesByWorkOrder(wol.workOrderNo)
      ),
      this.store.select(
        RequirementState.getRequirementsBySource(wol.workOrderNo, wol.lineNo)
      ),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([wols, reqs]) => {
        this.processWorkOrderNumbers(wols);
        this.requirements = [...reqs];
        this.formService.selectedRequirements$.next(this.requirements);
        this.processWorkOrderUsageNumbers(reqs);
      });
  }

  private initializeForm() {
    const resultDate = new Date(this.wol?.resultDatetime || '');
    return this.formBuilder.group({
      actual_Time: [
        {
          value: this.wol?.actualTime || 0,
          disabled: this.wol?.finished ? this.wol.finished : false,
        },
      ],
      feedbackDescription: [
        '',
        [Validators.maxLength(250), Validators.required],
      ],
      extFeedbackDescription: [''],
      failure_Code: [this.wol?.failureCode],
      feedback_Type: 'General',
      equipment_ID: this.wol?.equipmentId,
      finishedWorkOrderLine: this.wol?.finished ? this.wol.finished : false,
      meterValue: [
        this.wol?.resultsValue ?? '',
        this.isResultsInputRequired ? [Validators.maxLength(50)] : [],
      ],
      result_Datetime: isBefore(resultDate, new Date('2000-01-01'))
        ? new Date()
        : resultDate,
      technician_Code: [{ value: this.wol?.technicianCode, disabled: false }],
      unit_of_Measure_Code: this.wol?.unitOfMeasure,
    });
  }

  private processWorkOrderNumbers(wols: WorkOrderLine[]): void {
    const totalLines = wols.length;
    const reportedLines = wols.filter(
      (x) => x.finished || (x.actualTime && x.actualTime > 0)
    ).length;
    const totalEstimatedTime = this.formService.getTotalEstimatedTime(wols);
    const totalActualTime = this.getTotalActualTime(wols);
    // Minus one is added because we are not counting the currently opened WOL.
    this.calculatedInformation[0].value = `${reportedLines}/${totalLines}`;
    this.calculatedInformation[1].value = `${totalActualTime}/${totalEstimatedTime} h`;
  }

  private processWorkOrderUsageNumbers(reqs: RelatedRequirement[]): void {
    const totalEstimatedUsage = this.getTotalEstimatedUsage(reqs);
    const totalActualUsage = this.formService.getTotalActualUsage(reqs);
    this.calculatedInformation[2].value = `${totalActualUsage}/${totalEstimatedUsage}`;
  }

  private getNonReportedLinesMessage(wols: WorkOrderLine[]): string {
    const nonReportedLinesCount = this.getNonReportedLinesCount(wols);
    return this.getNonReportedLinesMessageText(nonReportedLinesCount - 1) + '.';
  }

  private getNonReportedLinesCount(wols: WorkOrderLine[]): number {
    const totalLines = wols.length;
    const reportedLines = wols.filter(
      (x) => x.finished || (x.actualTime && x.actualTime > 0)
    ).length;
    const nonReportedLinesBasedOnNav: number = totalLines - reportedLines;
    return this.wol?.finished ||
      (this.wol?.actualTime && this.wol?.actualTime > 0)
      ? nonReportedLinesBasedOnNav + 1
      : nonReportedLinesBasedOnNav;
  }

  private getTotalActualTime(wols: WorkOrderLine[]): number {
    let total = 0;
    wols.forEach((wol) => (total += wol.actualTime ?? 0));
    return total;
  }

  private getTotalEstimatedUsage(reqs: RelatedRequirement[]): number {
    let total = 0;
    reqs.forEach((req) => (total += req.expectedQuantity ?? 0));
    return total;
  }

  private getNonReportedLinesMessageText(nrl: number): string {
    if (nrl === 1)
      return this.translocoService.translate('oneWorkOrderLinesNotReportedKey');
    else if (nrl > 1)
      return this.translocoService.translate(
        'totalWorkOrderLinesNotReportedKeyParam',
        { nonReportedLines: nrl }
      );
    else
      return this.translocoService.translate(
        'zeroWorkOrderLinesNotReportedKey'
      );
  }

  private submitReport(): void {
    if (!this.wol) return;
    this.ngForm.disable({ emitEvent: false });
    const feedback = {
      feedback_Text: this.feedbackDescription ?? undefined,
      feedback_Type: this.feedbackType ?? undefined,
      failure_Code: this.failureCode ?? undefined,
      extended_Feedback: this.isExtendedFeedback,
    };

    const payload: ReportMaintenance = {
      work_Order_Line: {
        document_Type: this.wol.documentType ?? WorkOrderDocumentType.released,
        work_Order_No: this.wol.workOrderNo,
        equipment_ID: this.wol.equipmentId,
        line_No: this.wol.lineNo,
        technician_Code:
          this.ngForm.controls.technician_Code.value ?? this.wol.technicianCode,
        results_Value: this.meterValue,
        result_Datetime: this.resultDatetime ?? undefined,
        actual_Time: this.actualTime ?? 0,
        finished: this.ngForm.controls.finishedWorkOrderLine.value ?? false,
        feedback,
        usages: this.formService.mapUsagesPayloadFromUsages(this.requirements),
      },
    };

    const copyAttachments = [...this.attachments];
    this.store
      .dispatch(new ReportWorkOrderLineMaintenance(payload))
      .pipe(withLatestFrom(this.selectedWorkOrderLine$))
      .subscribe(
        ([_, wol]) => {
          if (copyAttachments.length > 0) this.addAttachment(copyAttachments);
          const id = (wol.workOrderNo ?? '') + wol.lineNo;
          this.store.dispatch(new UpdateCardObject(id, wol));
          this.defaultExit();
        },
        () => this.ngForm.enable({ emitEvent: false })
      );
  }

  private defaultExit(): void {
    this.ngForm.enable({ emitEvent: false });
    this.store.dispatch(
      new AddNotification(
        'Report successfullly sent to the database.',
        'success',
        'Submit Report'
      )
    );
    this.formOptions.emit(FormOptions.default);
    this.resetView();
  }

  private addAttachment(copyAttachments: ImportAttachment[]): void {
    const attPayload = copyAttachments.map((at) => ({
      documentNo: this.wol?.workOrderNo ?? '',
      lineNo: this.wol?.lineNo ?? 0,
      sourceType: DocStorDocumentType.WorkOrderLine,
      facility: this.wol?.facility ?? '',
      attachment: at.file as File,
    }));
    this.maintenanceService
      .addAttachments(
        attPayload,
        this.store.selectSnapshot(AuthState.isDocStore)
      )
      .pipe(take(1))
      .subscribe();
  }

  private validateFormTechnician(): void {
    const tech = this.store.selectSnapshot(AuthState.technician);
    if (this.wol?.technicianCode !== tech && !this.wol?.managedByTagProject)
      this.modalService
        .confirmDialog('lineIsNotAssignedToYouKey', 'technicianCodeKey', false)
        .pipe(
          filter((val) => val),
          take(1)
        )
        .subscribe(() => {
          this.ngForm.controls.technician_Code.setValue(tech);
          this.ngForm.controls.technician_Code.markAsDirty();
        });
  }
}
