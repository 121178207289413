import { Component, Inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';

import { DialogData } from '@models/dialog-data';
import { Equipment } from '@tag/graphql';
import { Observable, take, tap } from 'rxjs';
import { FormService } from '@forms-services/form.service';
import { ApiService } from '@services/api.service';
import { EquipmentTransfer } from '@api/types';
import { Apollo } from 'apollo-angular';

const lineFeed = '\n';

@Component({
  selector: 'app-equipment-transfer',
  templateUrl: './equipment-transfer.component.html',
  styleUrls: ['./equipment-transfer.component.css'],
})
export class EquipmentTransferComponent implements OnInit {
  readonly options = ['transfer', 'swap'];
  readonly selectedOption = signal<'transfer' | 'swap'>('transfer');
  readonly moveToEquipmentId = signal('');
  readonly equipmentId = signal('');
  readonly loading = signal(false);

  constructor(
    public dialogRef: MatDialogRef<EquipmentTransferComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      equipmentId: string;
    },
    private formService: FormService,
    private api: ApiService,
    private apollo: Apollo
  ) {}

  getEquipmentId(): Observable<Equipment | undefined> {
    return this.formService.getEquipmentId().pipe(
      take(1),
      tap((x) => {
        if (x) this.moveToEquipmentId.set(x.id);
      })
    );
  }

  /** LineFeed is used to show message on several lines*/
  /** Line can be translated if it is a key only */
  ngOnInit(): void {
    this.equipmentId.set(this.dialogData.equipmentId);
  }

  closeDialog(value: boolean): void {
    if (value) {
      const body: EquipmentTransfer = {
        equipmentTargetParent: this.moveToEquipmentId(),
        equipmentToMove: this.equipmentId(),
        swap: this.selectedOption() === 'swap',
      };

      this.loading.set(true);

      this.api
        .post('/equipments/transfer', body)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.dialogRef.close(value);
            this.apollo.client.cache.evict({
              id: 'ROOT_QUERY',
              fieldName: 'equipments',
            });
            this.apollo.client.cache.evict({
              id: 'ROOT_QUERY',
              fieldName: 'equipmentTrees',
            });
          },
          error: (error) => {
            this.dialogRef.close(false);
          },
          complete: () => {
            this.loading.set(false);
          },
        });
    } else this.dialogRef.close(value);
  }
}
