import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';

import { DialogData } from '@models/dialog-data';
import { Files } from '@models/files';

@Component({
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  selector: 'app-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.css'],
})
export class AddAttachmentComponent implements OnInit {
  isPicture = true;
  recordID = '';
  blobImg?: string | ArrayBuffer | null;
  file?: Files;

  constructor(
    public dialogRef: MatDialogRef<AddAttachmentComponent>,

    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.recordID = JSON.parse(this.data.data);
  }

  setAttachment(elem: any, type: number): void {
    let isImg = false;

    const oneFiles = new Files();

    oneFiles.name = elem.target.files[0].name;
    oneFiles.type = elem.target.files[0].type;
    oneFiles.file = elem.target.files[0];

    switch (oneFiles.type) {
      case 'image/bmp':
        isImg = true;
        break;

      case 'image/gif':
        isImg = true;
        break;

      case 'image/jpg':
        isImg = true;
        break;

      case 'image/jpeg':
        isImg = true;
        break;

      case 'image/png':
        isImg = true;
        break;

      case '/tiff':
        isImg = true;
    }

    if (isImg) {
      const reader = new FileReader();
      reader.readAsDataURL(oneFiles.file);
      reader.onload = (_event): void => {
        this.blobImg = reader.result;
      };
    } else {
      this.blobImg = 'assets/Imag/file.png';
    }
  }
}
