import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { FormReportMaintenanceComponent } from '@forms/form-report-maintenance/form-report-maintenance.component';
import { NotificationComponent } from '@notification/notification.component';
import { AddAttachmentComponent } from '@request/add-attachment/add-attachment.component';
import { ConfirmRequestComponent } from '@request/confirm-request/confirm-request.component';
import { ImgDiapoComponent } from '@request/img-diapo/img-diapo.component';
import { EquipmentTransferComponent } from '@cards/card-equipment/equipment-transfer/equipment-transfer.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  dialogRef: MatDialogRef<any> | undefined;

  constructor(public dialog: MatDialog) {}

  diaporamaFromSrc(src: any): MatDialogRef<any> {
    return this.dialog.open(ImgDiapoComponent, {
      panelClass: 'Diapo',
      data: { message: src },
    });
  }

  diaporama(url?: any): MatDialogRef<any> {
    return this.dialog.open(ImgDiapoComponent, {
      panelClass: 'Diapo',
      data: { data: url },
    });
  }

  confirmDeleteDialog(message?: string): Observable<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        panelClass: 'confirm-dialog',
        data: { data: message },
        maxWidth: '425px',
      })
      .afterClosed();
  }

  confirmDialog(
    message: string,
    title?: string,
    showWarningIcon?: boolean
  ): Observable<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        panelClass: 'confirm-dialog',
        data: { data: message, title, showWarningIcon },
        maxWidth: '425px',
      })
      .afterClosed();
  }

  transferEquipmentDialog(equipmentId: string): Observable<boolean> {
    return this.dialog
      .open(EquipmentTransferComponent, {
        data: { equipmentId },
        maxWidth: '425px',
      })
      .afterClosed();
  }

  loadModal(
    modalName:
      | 'Notification'
      | 'CardReportMaintenance'
      | 'AddAttachment'
      | 'ConfirmRequestComponent',
    message: string
  ): Observable<any> {
    if (!this.dialogRef)
      switch (modalName) {
        case 'Notification':
          this.dialogRef = this.dialog.open(NotificationComponent, {
            panelClass: 'panel-notification',
            hasBackdrop: false,
            data: {
              data: message,
            },
          });
          break;

        case 'CardReportMaintenance':
          this.dialogRef = this.dialog.open(FormReportMaintenanceComponent, {
            panelClass: 'attach-object',
            data: {
              data: message,
            },
          });
          break;

        case 'AddAttachment':
          this.dialogRef = this.dialog.open(AddAttachmentComponent, {
            panelClass: 'attach-object',
            data: {
              data: message,
            },
          });
          break;

        case 'ConfirmRequestComponent':
          this.dialogRef = this.dialog.open(ConfirmRequestComponent, {
            panelClass: ['Zoom-out', 'animated'], // "  ",
            data: { data: message },
          });
      }
    return (
      this.dialogRef
        ?.afterClosed()
        .pipe(tap((x) => (this.dialogRef = undefined))) || of(null)
    );
  }
}
