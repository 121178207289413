import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import type { RelatedRequirement } from '@tag/graphql';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  AddRequirement,
  DeleteRequirement,
  GetRequirement,
  GetRequirementsBySource,
  RemoveCachedRequirements,
  UpdateRequirement,
} from '@stores-actions/requirement.action';
import { RequirementStoreService } from '@stores-services/requirement-store.service';

export interface RequirementStateObject extends RelatedRequirement {
  id: string;
  chargeType: string;
}

export class SetWorkOrderRequirements {
  static readonly type = '[Requirement] Set Work Order Requirements';
  constructor(public workOrderNo: string, public requirements: RequirementStateObject[]) {}
}
export interface RequirementStateModel {
  requirements: RequirementStateObject[];
  workOrderRequirements: { [workOrderNo: string]: RequirementStateObject[] };
}

/**
 * Requirements metadata and action mappings.
 */
@State<RequirementStateModel>({
  name: 'requirement',
  defaults: {
    requirements: [],
    workOrderRequirements: {}
  }
})
@Injectable()
export class RequirementState {
  constructor(
    private requirementStoreService: RequirementStoreService,
    private store: Store,
    private apollo: Apollo
  ) {}

  static getRequirementsBySource(
    sourceNo: string,
    sourceLineNo?: number
  ): (state: RequirementStateModel) => RequirementStateObject[] {
    return createSelector(
      [RequirementState],
      (state: RequirementStateModel) => {
        let reqs = state.requirements.filter(
          (req) => req.sourceNo === sourceNo
        );
        if (sourceLineNo !== undefined && sourceLineNo !== null)
          reqs = reqs.filter((req) => req.sourceLineNo === sourceLineNo);
        return reqs;
      }
    );
  }

  @Selector()
  static getRequirements(
    state: RequirementStateModel
  ): RequirementStateObject[] {
    return state.requirements;
  }

  @Action(GetRequirementsBySource, { cancelUncompleted: true })
getRequirementsBySource(
  { getState, patchState }: StateContext<RequirementStateModel>,
  { sourceNo, sourceLineNo }: GetRequirementsBySource
): Observable<RequirementStateObject[]> {
  let filter = `Source_No eq '${sourceNo}'`;
  if (sourceLineNo) filter += ` and Source_Line_No eq ${sourceLineNo}`;

  return this.requirementStoreService.fetchRequirements(filter).pipe(
    tap((result) => {
      const state = getState();
      let reqs: RequirementStateObject[] = [];
      if (sourceLineNo) {
        reqs = state.requirements.filter(
          (req) =>
            !(req.sourceNo === sourceNo && req.sourceLineNo === sourceLineNo)
        );
      } else {
        reqs = state.requirements.filter((req) => req.sourceNo !== sourceNo);
      }

      patchState({
        requirements: [...reqs, ...result],
        workOrderRequirements: state.workOrderRequirements 
      });
    })
  );
}
  @Action(SetWorkOrderRequirements)
  setWorkOrderRequirements(ctx: StateContext<RequirementStateModel>, action: SetWorkOrderRequirements) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      workOrderRequirements: {
        ...state.workOrderRequirements,
        [action.workOrderNo]: action.requirements
      }
    });
  }

  @Action(GetRequirement, { cancelUncompleted: true })
  getRequirement(
    { getState, setState }: StateContext<RequirementStateModel>,
    { documentType, sourceNo, sourceLineNo, lineNo }: GetRequirement
  ): Observable<RequirementStateObject> {
    let state = getState();

    return this.requirementStoreService
      .fetchRequirement(sourceNo, sourceLineNo, lineNo)
      .pipe(
        tap((result) => {
          state = getState();
          const requirementList = [...state.requirements];

          const requirementIndex = requirementList.findIndex(
            (item) => item.id === result.id
          );
          if (~requirementIndex) requirementList[requirementIndex] = result;
          else requirementList.push(result);
          setState({
            ...state,
            requirements: requirementList,
          });
        })
      );
  }

  @Action(AddRequirement)
  addRequirement(
    ctx: StateContext<RequirementStateModel>,
    { payload }: AddRequirement
  ): Observable<RequirementStateObject> {
    return this.requirementStoreService.addRequirement(payload).pipe(
      tap((result) =>
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requirements',
        })
      )
    );
  }

  @Action(UpdateRequirement)
  updateRequirement(
    { getState }: StateContext<RequirementStateModel>,
    { patch, id }: UpdateRequirement
  ): Observable<RequirementStateObject> {
    const state = getState();

    const storeObj = state.requirements.find((fb) => fb.id === id);
    if (!storeObj)
      return of(this.requirementStoreService.generateObjectNotInStoreError(id));

    /* Removing the possibility of using the ID which only exist inside the store. */
    patch = patch.filter((p) => p.path !== '/id');

    return this.requirementStoreService
      .updateRequirement(
        storeObj.documentType,
        storeObj.sourceNo,
        storeObj.sourceLineNo,
        storeObj.lineNo,
        patch
      )
      .pipe(
        tap((result) =>
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'requirements',
          })
        )
      );
  }

  @Action(DeleteRequirement)
  deleteRequirement(
    { getState }: StateContext<RequirementStateModel>,
    { id }: DeleteRequirement
  ): Observable<void> {
    const state = getState();

    const storeObj = state.requirements.find((fb) => fb.id === id);
    if (!storeObj) return of();

    return this.requirementStoreService
      .deleteRequirement(
        storeObj.documentType,
        storeObj.sourceNo,
        storeObj.sourceLineNo,
        storeObj.lineNo
      )
      .pipe(
        tap(() =>
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'requirements',
          })
        )
      );
  }

  @Action(RemoveCachedRequirements)
removeCachedRequirements(
  { getState, setState }: StateContext<RequirementStateModel>,
  { workOrderNo, lineNo }: RemoveCachedRequirements
): void {
  const state = getState();
  const filteredArray = state.requirements.filter(
    (item) => !item.id.startsWith(`${workOrderNo}${lineNo}`)
  );
  setState({
    requirements: filteredArray,
    workOrderRequirements: state.workOrderRequirements 
  });
}
}
