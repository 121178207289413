<div class="row h-100" *transloco="let t">
  <div class="col-6 h-100" style="border-right: 1px solid lightblue">
    <img src="{{ blobImg }}" />
  </div>

  <div class="col-6">
    <div class="row mt-3">
      <div class="col-6"></div>
      <div class="col-6 menuForAttach">
        <a class="TAGicon icon-add_image-512">
          <input type="file" id="file" (change)="setAttachment($event, 0)" accept="image/*" />
        </a>
        <a class="TAGicon icon-add_file-512">
          <input type="file" id="file" (change)="setAttachment($event, 1)" />
        </a>
      </div>
    </div>

    <input type="file" (change)="setAttachment($event, 0)" />
    <ng-container *ngIf="!isPicture">
      <input type="text" #urlLink />
    </ng-container>

    <div class="row">
      <div class="col-6">
        {{ t('noKey') }}
      </div>

      <div class="col-6">
        {{ t('yesKey') }}
      </div>
    </div>
  </div>
</div>
