<div
  mat-dialog-content
  class="d-flex flex-column h-100 container p-3 text-center"
  *transloco="let t"
>
  <h5>{{ equipmentId() }}</h5>

  <div mat-dialog-actions class="w-full gap-3 pl-3 pr-3 text-center">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>{{ t('typeKey') }}</mat-label>
      <mat-select
        [ngModel]="selectedOption()"
        (ngModelChange)="selectedOption.set($event)"
      >
        <mat-option *ngFor="let option of options" [value]="option">
          {{ t(option + 'Key') }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <verosoft-design-button-input
      [label]="t('parentIdKey')"
      class="w-100"
      [optionsAsync]="getEquipmentId()"
      [ngModel]="moveToEquipmentId()"
      (ngModelChange)="moveToEquipmentId.set($event)"
      optionId="id"
      data-cy="cyAssetId"
    ></verosoft-design-button-input>

    <div class="d-flex w-100">
      <button
        mat-stroked-button
        class="flex-1"
        (click)="closeDialog(false)"
        [disabled]="loading()"
      >
        {{ t('cancelKey') }}
      </button>
      <button
        mat-raised-button
        [disabled]="loading()"
        class="response-button__yes flex-1"
        (click)="closeDialog(true)"
        data-cy="cyYes"
      >
        {{ t('yesKey') }}
      </button>
    </div>
  </div>
</div>
