<div style="height: 95%" *transloco="let t">
  <div class="row fixed-header mx-0">
    <div class="col-10 mt-3">
      <h5>{{ t('notificationsKey') }}</h5>
    </div>
    <div class="col-2 mt-3 text-right">
      <mat-icon (click)="close()" class="cursor-pointer">clear</mat-icon>
    </div>
    <div class="row" style="width: 100%; min-height: 30px; border-bottom: 1px solid #687b9b6e; margin: auto">
      <div class="col-5"></div>
      <div class="col-7" style="text-align: right">
        <mat-icon (click)="deleteAll()" class="cursor-pointer" matTooltip="{{ t('deleteAllNotificationsKey') }}">delete_sweep</mat-icon>
      </div>
    </div>
    <div class="col-12 text-center" style="margin-top: 8rem" *ngIf="!notifications.length">
      <mat-icon>notifications</mat-icon>
    </div>

    <div class="col-12 mt-2 text-center" *ngIf="!notifications.length">
      <h6>{{ t('noNotificationsKey') }}</h6>
    </div>
  </div>
  <ul *ngFor="let item of notifications">
    <li>
      <div class="row">
        <div class="col-1 mr-2">
          <ng-container [ngSwitch]="item.data">
            <ng-container *ngSwitchCase="'info'">
              <mat-icon class="cursor-pointer" style="color: #017bff">info</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'warning'">
              <mat-icon class="cursor-pointer" style="color: #ffc108">report_problem</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'success'">
              <mat-icon class="cursor-pointer" style="color: #5db300">check_circle</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'error'">
              <mat-icon class="cursor-pointer" style="color: #a4262c">error</mat-icon>
            </ng-container>
          </ng-container>
        </div>

        <div class="col">
          <div class="d-flex flex-column">
            <h6>{{ item.title }}</h6>
            <p class="mb-0">{{ item.body }}</p>
          </div>
        </div>

        <div class="text-right">
          <mat-icon aria-hidden="true" style="cursor: pointer; margin-right: 0.5rem" (click)="delete(item)">delete</mat-icon>
        </div>
      </div>
      <p class="mb-0 mt-2 text-right" style="font-size: 0.75rem">{{ getRelativeDate($any(item).timestamp) }}</p>
    </li>
  </ul>
</div>
