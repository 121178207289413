<table>
  <tbody>
    <tr *ngFor="let keys of hotkeys">
      <td>{{ $any(keys)[1] }}</td>

      <td class="text-right">
        <kbd>{{ $any(keys)[0] }}</kbd>
      </td>
    </tr>
  </tbody>
</table>
