import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { HotkeysDialogComponent } from '@shared/hotkeys-dialog/hotkeys-dialog.component';

type Options = {
  element: any;
  description: string | undefined;
  keys: string;
};

@Injectable({
  providedIn: 'root',
})
export class Hotkeys {
  keyDialog?: MatDialogRef<any>;
  hotkeys = new Map();
  defaults: Partial<Options> = {
    element: this.document,
  };

  constructor(private eventManager: EventManager, @Inject(DOCUMENT) private document: Document, private dialog: MatDialog) {
    this.addShortcut({ keys: 'shift.?', description: 'Show keys' }).subscribe(() => {
      this.openHelpModal();
    });
  }

  // Make sure to unsubsribe on destroy of your component to not create memory leaks.
  addShortcut(options: Partial<Options>): Observable<any> {
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;

    if (merged.description) this.hotkeys.set(merged.keys, merged.description);

    return new Observable((observer) => {
      const handler = (e: any): void => {
        e.preventDefault();
        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(merged.element, event, handler);

      return (): void => {
        dispose();
        this.hotkeys.delete(merged.keys);
      };
    });
  }

  openHelpModal(): void {
    if (this.keyDialog) this.keyDialog.close();
    this.keyDialog = this.dialog.open(HotkeysDialogComponent, {
      width: '500px',
      data: this.hotkeys,
    });
  }
}
