import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';

import {
  AddI18nNotification,
  AddNotification,
  DeleteAllNotification,
  DeleteNotification,
  SetSelectedNotification,
} from '@stores-actions/notification.action';

export class NotificationStateModel {
  notifications: Notification[] = [];
  selectedNotification!: Notification | null;
}

/**
 * Work Order Lines metadata and action mappings.
 */
@State<NotificationStateModel>({
  name: 'notification',
  defaults: {
    notifications: [],
    selectedNotification: null,
  },
})
@Injectable()
export class NotificationState {
  constructor(private toastrService: ToastrService, private translocoService: TranslocoService) {}

  @Selector()
  static getNotifications(state: NotificationStateModel): Notification[] {
    return state.notifications;
  }

  @Selector()
  static getSelectedNotification(state: NotificationStateModel): Notification | null {
    return state.selectedNotification;
  }

  @Action(AddNotification)
  addNotification({ getState, patchState }: StateContext<NotificationStateModel>, { body, type, title }: AddNotification): void {
    switch (type) {
      case 'info':
        this.toastrService.info(body, title);
        break;
      case 'warning':
        this.toastrService.warning(body, title);
        break;
      case 'success':
        this.toastrService.success(body, title);
        break;
      case 'error':
        this.toastrService.error(body, title);
        break;
      default:
        break;
    }
    const notification = { title, body, data: type, timestamp: Date.now() } as unknown as Notification;
    const state = getState();
    patchState({
      notifications: [...state.notifications, notification],
      selectedNotification: notification,
    });
  }

  @Action(AddI18nNotification)
  addI18nNotification({ getState, patchState }: StateContext<NotificationStateModel>, { body, type, title }: AddI18nNotification): void {
    const bodyI18n = body.params ? this.translocoService.translate(body.key, body.params) : this.translocoService.translate(body.key);
    const titleI18n = this.translocoService.translate(title || '');

    switch (type) {
      case 'info':
        this.toastrService.info(bodyI18n, titleI18n);
        break;
      case 'warning':
        this.toastrService.warning(bodyI18n, titleI18n);
        break;
      case 'success':
        this.toastrService.success(bodyI18n, titleI18n);
        break;
      case 'error':
        this.toastrService.error(bodyI18n, titleI18n);
        break;
      default:
        break;
    }
    const notification = { title: titleI18n, body: bodyI18n, data: type, timestamp: Date.now() } as unknown as Notification;
    const state = getState();
    patchState({
      notifications: [...state.notifications, notification],
      selectedNotification: notification,
    });
  }

  @Action(DeleteNotification)
  deleteNotification({ getState, setState }: StateContext<NotificationStateModel>, { timestamp }: DeleteNotification): void {
    const state = getState();
    const nots = state.notifications as any;
    const filteredArray = nots.filter((item: any) => item.timestamp !== timestamp);
    setState({
      ...state,
      notifications: filteredArray,
      selectedNotification: filteredArray[0],
    });
  }

  @Action(DeleteAllNotification)
  deleteAllNotification({ getState, setState }: StateContext<NotificationStateModel>): void {
    const state = getState();
    setState({
      ...state,
      notifications: [],
    });
  }

  @Action(SetSelectedNotification)
  setSelectedNotification({ getState, setState }: StateContext<NotificationStateModel>, { payload }: SetSelectedNotification): void {
    const state = getState();
    setState({
      ...state,
      selectedNotification: payload,
    });
  }
}
