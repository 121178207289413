export class Files {
  type = '';
  name = '';
  file: any;
  isLink = false;

  typeAttach = 0;
  files(): void {
    this.isLink = false;
  }
}
