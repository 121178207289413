import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import type { Request } from '@tag/graphql';

import { DialogData } from '@models/dialog-data';
import { LoginOption } from '@models/login-option';
import { AuthState } from '@stores-states/authentication.state';

@Component({
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  selector: 'app-confirm-request',
  templateUrl: './confirm-request.component.html',
  styleUrls: ['./confirm-request.component.css'],
})
export class ConfirmRequestComponent implements OnInit {
  createdRequest: Request | undefined;

  constructor(
    public dialogRef: MatDialogRef<ConfirmRequestComponent>,
    public router: Router,
    private readonly store: Store,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.getRequest(this.data.data);
  }

  goToTracking(): void {
    this.router.navigate(['/AddReq']);
  }

  redirectConfirmRequest(): void {
    if (
      this.store.selectSnapshot(AuthState.portalType) === LoginOption.technician
    ) {
      this.router.navigate(['/AddReq']);
    }
    this.dialogRef.close();
  }

  getRequest(data: any): void {
    this.createdRequest = data;
  }
}
